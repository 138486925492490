// bootstrap overrides
$breadcrumb-bg: none;
$breadcrumb-padding-vertical: 10px;
$breadcrumb-padding-horizontal: 0;
$breadcrumb-color: $text-gray;
$breadcrumb-border-color: $text-off-white;

.breadcrumbs {
  background: #fafafa;
  border-bottom: solid 1px $breadcrumb-border-color;
  min-height: 60px;

  h1 {
    margin-top: 16px;
    font-size: 22px;
  }

  a {
    color: $breadcrumb-color;
  }

  .breadcrumb {
    position: relative;
    top: 10px;
  }
}


@media (max-width: $grid-float-breakpoint-max) {
  .breadcrumbs h1,
  .breadcrumbs .breadcrumb {
    width: 100%;
    text-align: center;
  }
}
