footer {
  background-color: $off-black;
  color: $text-off-white;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  p {
    color: $text-off-white;
    padding-top: 10px;
  }
}

.headline {
  margin: 5px 0 5px 0;
  border-bottom: 2px solid $concur-blue;
  padding-bottom: 5px;
  font-size: 22px;
  color: $text-off-white;
}

.footer-copyright a{
  color: white;
}

address {
  margin-bottom: 10px;
  line-height: 1.333;

}

.footer-copyright {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.footer-social {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 3px;
  padding-bottom: 2px;
}

.social-icons {
  padding-top: 9px;
  float: right;
}

.social-icons a {
  color: $text-gray;
}

@media (max-width: $grid-float-breakpoint-max) {
  .footer-copyright,
  .social-icons {
    float: none;
    text-align: center;
  }
}

.footer-github {
  margin: 30px 0;

  span {
    margin: 0 10px;
  }

  * {
    color: $text-off-white;
  }
}

.company-description {
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
}

.link-list li {
  border-top: solid 1px #353535;

  &:first-child {
    border-top: none !important;
  }

  i {
    color: #bbb;
    float: right;
    margin-top: 10px;
  }
}

.link-list a {
  color: $text-off-white;
  font-size: 11px;
  padding: 3px 0px;
  display: inline-block;
  text-transform: uppercase;

  &:hover {
    color: $text-off-white;
  }
}
