$notice-color: $dark-gray;
$notice-text-color: $white;
$notice-height: 32px;

.navbar-notice {
  height: $notice-height;
  padding: 5px 0;
  background-color: $notice-color;
  color: $notice-text-color;
  font-weight: 200;

  a, a:hover {
    color: $notice-text-color;
  }
}
