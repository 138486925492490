#topcontrol {
  color: white;
  z-index: 99;
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: #222;
  position: relative;
  right: 14px !important;
  bottom: 11px !important;
  border-radius: 3px !important;
}

#topcontrol:after {
  top: 2px;
  left: 8.5px;
  content: "\f106";
  position: absolute;
  text-align: center;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

#topcontrol:hover {
  color: white;
  background: #72c02c;
  -webkit-transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
       -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
}
