@import "colors";

$border-radius-base: 0;
$font-family-base: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$headings-font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$headings-font-weight: 200;
$headings-font-color: #444;
$text-color: $text-gray;

$grid-float-breakpoint: 992px;
$grid-float-breakpoint-max: $grid-float-breakpoint - 1;

$link-color: $concur-link-color;
$link-hover-color: $concur-link-color;

$header-height: 5em;
$footer-height: 3em;

// components
@import "components/navbar";
@import "components/navbar-searchbox";
@import "components/navbar-notice";
@import "components/jumbotrons";
@import "components/breadcrumbs";
@import "components/footer";
@import "components/back-to-top";
@import "components/sidebar";
@import "components/code-highlighting";

// pages
@import "pages/shared";
@import "pages/home";
@import "pages/getting-started";
@import "pages/go-to-market";
@import "pages/why-concur";
@import "pages/swagger";
@import "pages/blog";
@import "pages/search";
@import "pages/markdown";

// third party/vendor
@import "vendor/github-markdown";
@import "vendor/animate";

//Bootstrap references
@import "plugins/bootstrap/css/bootstrap-theme.css";
@import "plugins/bootstrap/css/bootstrap-theme.min.css";
@import "plugins/bootstrap/css/bootstrap.css";
@import "plugins/bootstrap/css/bootstrap.min.css";

// Bootstrap component override
@import "components/buttons";
@import "components/dropdowns";

// Other styles

// eliminates positioning jumps when a scrollbar is missing
html {
  overflow-y: hidden;
}
body {
  h1, h2, h3, h4, h5, h6 {
    color: $text-gray;
    margin-top: 5px;
    text-shadow: none;
    font-weight: normal;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }

  p {
    font-size: 16px;
  }

  overflow-y: hidden;
  height: 100%;
}
#markdown-body{
  height: 100vh;
}
.main-content {
  margin-top: 75px;
  z-index: -1;
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}
.jumbotron {
  margin-bottom: 0;
}
@media (max-width: 874px){
  //adjusts logo in mobile view to be centered on the navbar.
  .navbar-brand{
    margin-top: -2%;
  }
  .main-content {
    margin-top: -10px;
    z-index: -1;
    position: absolute;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
  }
  #left-navigation{
    margin-bottom: 0%;
    padding-bottom: 0%;
  }
  #markdown-toc{
    display:none;
  }
  #markdown-body{
    margin-top: -10px;
  }
}
