/*--- Default button styles ---*/
.btn {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  line-height: 19px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 3px;
  background-color: $bright-blue;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;

  &:hover {
    background-color: #27beff;
    color: #fff;
  }

  &:active,
  &:focus {
    text-decoration: underline;
    color: #fff;
  }

  &.is-rounded {
    border-radius: 290486px;
    margin-top: 12px;
  }
}

button.btn, input.btn {
  border: none;
  cursor: pointer;
}

/*--- Button Sizing styles ---*/
.btn.x-small, .btn-ghost.x-small {
  padding: 2px 8px 1px;
  font-size: 13px;
  line-height: 16px;
}

.btn.small, .btn-ghost.small {
  padding: 7px 13px 6px;
  font-size: 15px;
  line-height: 16px;
}
.btn.large, .btn-ghost.large {
  padding: 17px 30px 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

/*--- Concur Blue ---*/
.btn.concur-blue {
  background-color: #0078c9;
  color: #fff;

  &:hover {
    background-color: #0088e3;
    color: #fff;
  }
}

/*--- Dark Blue ---*/
.btn.dark-blue {
  background-color: #004a7d;
  color: #fff;

  &:hover {
    background-color: #0b59a9;
    color: #fff;
  }
}

/*--- Orange ---*/
.btn.orange {
  background-color: #d25533;
  color: #fff;

  &:hover {
    background-color: #ec6039;
    color: #fff;
  }
}

/*--- Bright Green ---*/
.btn.bright-green {
  background-color: #89bf42;
  color: #fff;

  &:hover {
    background-color: #92cb53;
    color: #fff;
  }
}

/*--- Dark Green ---*/
.btn.dark-green {
  background-color: #548d3d;
  color: #fff;

  &:hover {
    background-color: #59a33d;
    color: #fff;
  }
}

/*--- Grey ---*/
.btn.grey {
  margin-right: 6px;
  background-color: #9b9c9d;
  color: #fff;

  &:hover {
    background-color: #adaeae;
    color: #fff;
  }
}

/*--- White ---*/
.btn.white {
  background-color: #fff;
  color: #444;

  &:hover {
    background-color: #e5e5e5;
    color: #444;
  }
}

/*--- Yellow ---*/
.btn.yellow {
  background-color: #f4a900;
  color: #fff;

  &:hover {
    background-color: #f8b723;
    color: #fff;
  }
}

/*--- Ghost Buttons ---*/
.btn-ghost {
  display: inline-block;
  padding: 10px 25px 9px;
  text-decoration: none;
  font-size: 18px;
  line-height: 19px;
  background: transparent;
  border-width: 2px;
  border-style: solid;
  -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
          border-radius: 3px;
  -webkit-transition: all 0.25s ease-in-out;
     -moz-transition: all 0.25s ease-in-out;
          transition: all 0.25s ease-in-out;
}

/*--- concur-blue ---*/
.btn-ghost.concur-blue {
  border-color: $concur-blue;
  color: $concur-blue;

  &:hover {
    background-color: $concur-blue;
    color: #fff;
    text-decoration: none;
  }
}

.btn-ghost.white {
  color: white;
  border-color: white;
  background-color: rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: white;
    color: $concur-blue;
    text-decoration: none;
  }
}
