$concur-blue: #0078c9;
$bright-blue: #00a9f2;
$dark-blue: #004a7d;
$bright-green: #89bf42;
$dark-green: #548d3d;
$orange: #d25533;
$yellow: #f4a900;
$light-gray: #cbcbc4;
$dark-gray: #898d8d;
$text-gray: #555;
$text-off-white: #eee;
$white: #fff;
$black: #000;
$off-black: #111;
$concur-link-color: #0275bc;
$red: #d9534f;
