// bootstrap overrides
$navbar-default-bg: $black;
$navbar-height: $header-height;
$navbar-border-radius: 0;

.app-name {
  color: white;
}
.navbar-nav {
  // apply border so text doesn't jump on hover
  > li > a {
    border-bottom: 2px solid transparent;
    text-transform: uppercase;
  }
  > li.active > a,
  > li > a:hover {
    border-bottom: 2px solid $concur-blue;
  }

  > li a.active {
    border-bottom: solid 2px $concur-blue;
  }
}
a.navbar-brand {
  display: inline-block;
  padding: 0;
  img {
    display: inline-block;
    float: left;
    height: 67px;
    padding: 19px;
  }
  .app-name {
    display: inline-block;
    padding-top: 25px;
    font-size: 20px;

    &:hover {
      color: $concur-blue;
    }
  }
}

@media (min-width: 875px) {
  // eliminate margin between navbar and page content
  nav.navbar {
    margin-top:0;
    top: 0;
    margin-bottom: 0;
    left: 0;
    position: fixed;
    height: $navbar-height;
    width: 100%;
  }
}
@media (max-width: 874px){
  //adjusts logo in mobile view to be centered on the navbar.
  .navbar-brand{
    margin-top: -2%;
  }
}

