.content .markdown-body {
  color: $text-gray;

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }

  overflow-y: scroll;
  padding-top: 8px;
  padding-bottom: 8px;
}

.markdown-toc {
  overflow-y: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.markdown-toc-content {
  position: absolute;
  li a {
    color: $concur-link-color;
  }
}

.markdown-body {
  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  table > thead > tr > th,
  table > tbody > tr > th,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > tbody > tr > td,
  table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }

  table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
  }

  table > caption + thead > tr:first-child > th,
  table > colgroup + thead > tr:first-child > th,
  table > thead:first-child > tr:first-child > th,
  table > caption + thead > tr:first-child > td,
  table > colgroup + thead > tr:first-child > td,
  table > thead:first-child > tr:first-child > td {
    border-top: 0;
  }

  table > tbody + tbody {
    border-top: 2px solid #ddd;
  }

  li a {
    color: $concur-link-color;
  }
}

.markdown-toc-content>.toc-list{
  position:relative;
}
.markdown-toc-content>.toc-list li{
  list-style:disc;
}
.toc-list{
  margin:0;
  padding-left:20px;
}
