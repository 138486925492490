.dropdown:hover {
  background-color: #292929;

  .caret {
    border-top: 0;
    border-bottom: 4px solid;
  }
}

.dropdown-menu {
  min-width: 200px;
  padding: 0;

  li {
    min-width: 250px;
  }

  li > a {
    color: $concur-blue;
    font-weight: 300;
    padding: 5px 15px;

    &:hover,
    &:focus {
      color: $white;
      background-color: $dark-gray;
    }
  }

  .column-icon {
    color: $concur-blue;
    padding: 10px 10px 0;
  }

  .column-title {
    font-size: 16px;
    padding: 5px 15px;
  }

  &.two-column-menu {
    min-width: 450px;

    .col-sm-6:nth-child(1) {
      padding-right: 0 !important;
    }

    .col-sm-6:nth-child(2) {
      padding-left: 0 !important;
    }

    li {
      min-width: 200px;
    }
  }

  &.three-column-menu {
    min-width: 600px;

    .col-sm-4:nth-child(1) {
      padding-right: 0 !important;
    }

    .col-sm-4:nth-child(2) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .col-sm-4:nth-child(3) {
      padding-left: 0 !important;
    }

    li {
      min-width: 180px;
    }
  }

  .multi-column-dropdown {
    list-style-type: none;
    margin: 0px;
    padding: 0px;

    li > a {
      display: block;
      line-height: 1.428571429;
      color: $concur-blue;

      &:hover {
        background-color: #999;
        color: white;
        text-decoration: none;
      }
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
  }
}

@media (max-width: $grid-float-breakpoint-max) {
  .dropdown-menu {
    li > a {
      border-bottom: 1px solid $off-black;
    }

    .column-icon {
      padding-left: 20px;
    }

    .column-title {
      color: $light-gray;
      padding-left: 25px;
    }

    &.two-column-menu {
      .col-sm-6:nth-child(1),
      .col-sm-6:nth-child(2) {
        padding-left: 25px !important;
      }

      .column-icon {
        padding-left: 10px;
      }

      .column-title {
        padding-left: 15px;
      }
    }

    &.three-column-menu {
      .col-sm-4:nth-child(1),
      .col-sm-4:nth-child(2),
      .col-sm-4:nth-child(3) {
        padding-left: 25px !important;
      }

      .column-icon {
        padding-left: 10px;
      }

      .column-title {
        padding-left: 15px;
      }
    }

    .multi-column-dropdown {
      li > a {
        color: white;
      }
    }
  }
}
